import React from 'react'
import styles from './discount-box.scss'
import Card from "react-bootstrap/Card";
import {Link} from "gatsby";
import {GoStar} from "react-icons/go"

class DiscountBox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Card className="my-5 p-4 discount-box-card">
        <div className="discount-box-title mb-2">{this.props.title}</div>
        <p>{this.props.testimonial}</p>
        <div className="d-flex mb-4 mt-1" title="Review Score: 5">
          <GoStar className="custom-star" title="StarIcon"/>
          <GoStar className="custom-star" title="Star Icon"/>
          <GoStar className="custom-star" title="Star Icon"/>
          <GoStar className="custom-star" title="Star Icon"/>
          <GoStar className="custom-star" title="Star Icon"/>
        </div>
        <Link to={this.props.ctaUrl} className="btn btn-success col-12 p-2">{this.props.ctaText}</Link>
        <p className="text-center mt-4 mb-0">{this.props.message}</p>
      </Card>
    )
  }
}

export default DiscountBox